
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect, ReactReduxContext, } from 'react-redux';
import patrick from "../assets/patrick.png";
import { createState, updateState, } from '../redux/actions/StateActions.js';
import { State } from '../redux/model/State.js';
import FoodItem from './FoodItem';
import arrowDown from '../assets/down.png'
import arrowUp from '../assets/up.png'
import { Link } from 'react-router-dom';


// app component
function FibreCounter() {

  const dispatch = useDispatch()
  const state = useSelector(state => state.state_reducer[0])
  const [expandMenu, setExpandMenu] = useState(false);
  const [expandFruits, setExpandFruits] = useState(true);
  const [expandVegetables, setExpandVegetables] = useState(true);
  const [expandGrains, setExpandGrains] = useState(true);
  const [expandPasta, setExpandPasta] = useState(true);
  const [expandCereal, setExpandCereal] = useState(true);
  const [expandNuts, setExpandNuts] = useState(true);
  const [search, setSearch] = useState('');

  // component did mount
  useEffect(() => {

    // resize listener
    window.addEventListener("resize", updateSize);

    // unmount component
    return () => {
      window.removeEventListener("resize", updateSize);
    }
  }, [])

  // component did update
  useEffect(() => {

  })

  // reset the window size
  function updateSize() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }

  function setWidth(value) {

    const f = state;
    f.width = value;
    dispatch(updateState(f))

  }

  function setHeight(value) {

    const f = state;
    f.height = value;
    dispatch(updateState(f))

  }

  // render each burger
  // map state to sprite
  function foodToShowFruits() {

    return state.foodItems
      .filter((food, i) => {
        if (food.type == 'fruit') {
          return food
        }
      })
      .map((f, i) => {
        return (
          <FoodItem key={f.food} type={f.food} size={f.size} count={f.count} fiber={f.fiber} options={f.options} />
        )
      })
  }

  function foodToShowVegetables() {
    return state.foodItems
      .filter((food, i) => {
        if (food.type == 'vegetable') {
          return food
        }
      })
      .map((f, i) => {
        return (
          <FoodItem key={f.food} type={f.food} size={f.size} count={f.count} fiber={f.fiber} options={f.options} />
        )
      })
  }

  function foodToShowGrains() {
    return state.foodItems
      .filter((food, i) => {
        if (food.type == 'breads') {
          return food
        }
      })
      .map((f, i) => {
        return (
          <FoodItem key={f.food} type={f.food} size={f.size} count={f.count} fiber={f.fiber} options={f.options} />
        )
      })
  }

  function foodToShowPasta() {
    return state.foodItems
      .filter((food, i) => {
        if (food.type == 'pasta') {
          return food
        }
      })
      .map((f, i) => {
        return (
          <FoodItem key={f.food} type={f.food} size={f.size} count={f.count} fiber={f.fiber} options={f.options} />
        )
      })
  }

  function foodToShowCereal() {
    return state.foodItems
      .filter((food, i) => {
        if (food.type == 'cereal') {
          return food
        }
      })
      .map((f, i) => {
        return (
          <FoodItem key={f.food} type={f.food} size={f.size} count={f.count} fiber={f.fiber} options={f.options} />
        )
      })
  }

  function foodToShowNuts() {
    return state.foodItems
      .filter((food, i) => {
        if (food.type == 'nuts') {
          return food
        }
      })
      .map((f, i) => {
        return (
          <FoodItem key={f.food} type={f.food} size={f.size} count={f.count} fiber={f.fiber} options={f.options} />
        )
      })
  }

  function foodToShowSearch() {
    return state.foodItems
      .filter((food, i) => {
        if (food.food.toLowerCase().includes(search.toLowerCase())) {
          return food
        }
      })
      .map((f, i) => {
        return (
          <FoodItem key={f.food} type={f.food} size={f.size} count={f.count} fiber={f.fiber} options={f.options} />
        )
      })
  }

  function summary() {
    return state.foodItems
      .filter((food) => {
        if (food.count > 0) {
          return food
        }
      })
      .map((f, i) => {
        return (
          <div key={f.food} style={summaryRow}>
            <h1 style={columnSubLarge}>
              {f.food}
            </h1>

            <h1 style={columnSub}>
              {f.size}
            </h1>

            <h1 style={columnSub}>
              {calculateFiber(f)} g
            </h1>

            <h1 style={columnSubSmall}>
              {f.count}
            </h1>
          </div>
        )
      })
  }

  function calculateTotal() {
    var sum = 0;

    state.foodItems
      .forEach(food => {
        if (food.count > 0) {

          var multiplier;
          food.options.forEach(option => {
            if (option.size == food.size) {
              multiplier = option.multiplier
            }
          });

          sum = sum + (food.fiber * multiplier * food.count)
        }
      })

    return sum;
  }

  function calculateFiber(food) {

    var multiplier;
    food.options.forEach(option => {
      if (option.size == food.size) {
        multiplier = option.multiplier
      }
    });
    var value = food.fiber * multiplier
    return parseFloat(value.toFixed(2))
  }

  function searchFood(e) {

    setSearch(e.target.value)
  }

  function mainApp() {

    if (state) {

      return (

        <div style={parentContainerStyle}>
          <div style={container}>
            <div style={expandMenu ? topContainerStyleSmall : topContainerStyleLarge}>
              <div style={centered}>
                <div style={searchContainer}>
                  <div style={searchBox}>
                    <input value={search} type={'text'} placeholder='Search' style={searchInput} onChange={(e) => { searchFood(e) }} />
                  </div>
                </div>
                {search != '' ? foodToShowSearch()
                  :
                  <>
                    <a onClick={() => { setExpandFruits(!expandFruits) }}>
                      <div style={dropdownMenu}>
                        <div style={titleBox}>
                          <h1 style={title}>Fruits</h1>
                        </div>
                        <div style={dropdownBox}>
                          <img height={30} src={expandFruits ? arrowUp : arrowDown} />
                        </div>
                      </div>
                    </a>
                    {expandFruits && foodToShowFruits()}

                    <a onClick={() => { setExpandVegetables(!expandVegetables) }}>
                      <div style={dropdownMenu}>
                        <div style={titleBox}>
                          <h1 style={title}>Vegetables</h1>
                        </div>
                        <div style={dropdownBox}>
                          <img width={30} src={expandVegetables ? arrowUp : arrowDown} />
                        </div>
                      </div>
                    </a>
                    {expandVegetables && foodToShowVegetables()}

                    <a onClick={() => { setExpandGrains(!expandGrains) }}>
                      <div style={dropdownMenu}>
                        <div style={titleBox}>
                          <h1 style={title}>Breads</h1>
                        </div>
                        <div style={dropdownBox}>
                          <img width={30} src={expandGrains ? arrowUp : arrowDown} />
                        </div>
                      </div>
                    </a>
                    {expandGrains && foodToShowGrains()}

                    <a onClick={() => { setExpandCereal(!expandCereal) }}>
                      <div style={dropdownMenu}>
                        <div style={titleBox}>
                          <h1 style={title}>Cereal</h1>
                        </div>
                        <div style={dropdownBox}>
                          <img width={30} src={expandCereal ? arrowUp : arrowDown} />
                        </div>
                      </div>
                    </a>
                    {expandCereal && foodToShowCereal()}

                    <a onClick={() => { setExpandPasta(!expandPasta) }}>
                      <div style={dropdownMenu}>
                        <div style={titleBox}>
                          <h1 style={title}>Pasta/Rice</h1>
                        </div>
                        <div style={dropdownBox}>
                          <img width={30} src={expandPasta ? arrowUp : arrowDown} />
                        </div>
                      </div>
                    </a>
                    {expandPasta && foodToShowPasta()}

                    <a onClick={() => { setExpandNuts(!expandNuts) }}>
                      <div style={dropdownMenu}>
                        <div style={titleBox}>
                          <h1 style={title}>Nuts</h1>
                        </div>
                        <div style={dropdownBox}>
                          <img width={30} src={expandNuts ? arrowUp : arrowDown} />
                        </div>
                      </div>
                    </a>
                    {expandNuts && foodToShowNuts()}

                    
                  </>}
              </div>
            </div>
          </div>
          {expandMenu ?
            <>
              <div style={bottomContainerStyleLarge}>
                {/*<div style={totalContainer}>
                  <h1 style={totalTitle}>
                    Total: {parseFloat(calculateTotal().toFixed(2))} grams of fiber
                  </h1>
                </div>*/}
                <div style={totalContainerLarge}>
                  <div style={totalContainerBody}>
                    <h1 style={totalHeader}>
                      Total
                    </h1>
                  </div>
                  <div style={totalContainerBody}>
                    <h1 style={totalBody}>
                      {parseFloat(calculateTotal().toFixed(2))} grams of fiber
                    </h1>
                  </div>
                </div>
                <div style={summaryRow}>
                  <h1 style={columnLarge}>
                    Food
                  </h1>

                  <h1 style={column}>
                    Portion
                  </h1>

                  <h1 style={column}>
                    Fiber
                  </h1>

                  <h1 style={columnSmall}>
                    Qty
                  </h1>
                </div>
                {summary()}
              </div>
              <div onClick={() => { setExpandMenu(false) }} style={expandButtonContainerLarge}>
                <div style={expandButton}>
                  <img style={expandImg} src={arrowDown} />
                </div>
              </div>
            </>
            :
            <>
              <div style={bottomContainerStyleSmall}>
                <div style={totalContainerSmall}>
                  <div style={totalContainerBody}>
                    <h1 style={totalHeader}>
                      Total
                    </h1>
                  </div>
                  <div style={totalContainerBody}>
                    <h1 style={totalBody}>
                      {parseFloat(calculateTotal().toFixed(2))} grams of fiber
                    </h1>
                  </div>
                </div>
              </div>
              <div onClick={() => { setExpandMenu(true) }} style={expandButtonContainer}>
                <div style={expandButton}>
                  <img style={expandImg} src={arrowUp} />
                </div>
              </div>
            </>
          }
        </div>
      )
    }
  }

  return mainApp()

}

export default FibreCounter;

const columnOne = {
  width: '25%',
  height: '100%',
  display: 'inline-block'
}

const columnTwo = {
  width: '50%',
  height: '100%',
  display: 'inline-block',
  //verticalAlign:'super'
}

const expandButtonContainer = {

  position: 'absolute', bottom: '5%', right: '5%', transform: 'translate(0%,50%)',
  display: 'flex', justifyContent: 'center', alignItems: 'center',

}

const expandButtonContainerLarge = {

  position: 'absolute', bottom: '35%', right: '5%', transform: 'translate(0%,50%)',
  display: 'flex', justifyContent: 'center', alignItems: 'center',

}

const expandButton = {
  width: '40px',
  height: '40px',
  backgroundColor: 'white',
  boxShadow: '0px 3px 12px 0px rgba(0,0,0,0.4)',
  display: 'flex', justifyContent: 'center', alignItems: 'center',
  borderRadius: '30px',

}

const expandImg = {
  width: '25px',
  opacity: 0.5
}

const searchContainer = {
  height: '100px', width: '100%',
  display: 'flex', justifyContent: 'center', alignItems: 'center'
}

const searchBox = {
  marginTop: '30px', marginBottom: '20px',
  height: '50px', width: '80%',
  borderRadius: '5px',
  backgroundColor: 'white',
  boxShadow: '0px 3px 12px 0px rgba(0,0,0,0.4)',
}

const searchInput = {
  height: '50px', width: '95%',
  borderRadius: '5px', outline: 'none', border: 'none',
  fontSize: '23px', fontWeight: 'bold', color: 'grey',
  paddingLeft: '5%'
}

const parentContainerStyle = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0, left: 0,
  overflow: 'hidden',
  fontFamily: 'Arial',
  backgroundImage: 'linear-gradient(purple, yellow)',
}

const container = {
  height: '100%',
  width: '100%',
  display: 'flex', justifyContent: 'center', alignItems: 'start',
  overflowX: 'hidden'
}

const topContainerStyleSmall = {
  maxWidth: '500px',
  width: '100%',
  height: '60%',
  overflow: 'scroll',
  backgroundColor: 'white'
}

const topContainerStyleLarge = {
  maxWidth: '500px',
  width: '100%',
  height: '90%',
  overflow: 'scroll',
  backgroundColor: 'white'
}

const bottomContainerStyleSmall = {
  width: '100%',
  height: '10%',
  position: 'absolute',
  top: '90%', left: 0,
  overflow: 'hidden',
  backgroundColor: 'white',
  boxShadow: '0px 3px 12px 3px rgba(0,0,0,0.64)',
  overflow: 'hidden'
}

const bottomContainerStyleLarge = {
  width: '100%',
  height: '40%',
  position: 'absolute',
  bottom: 0, left: 0,
  overflow: 'scroll',
  backgroundColor: 'white',
  boxShadow: '0px 3px 12px 3px rgba(0,0,0,0.64)',
  overflow: 'scroll',
  //display: 'flex', justifyContent: 'start', alignItems: 'center', flexDirection:'column'

}

const centered = {
  width: '100%',
  height: '100%',
  display: 'flex',

  alignAitems: 'center',
  flexDirection: 'column'
}

const totalContainer = {
  width: '100%',
  height: '30px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'start',
  marginBottom: '20px'
}

const totalContainerSmall = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  flexDirection: 'column'
}

const totalContainerLarge = {
  width: '100%',
  height: '10%',
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  flexDirection: 'column',
  marginBottom: '50px'
}

const totalHeader = {
  fontSize: '20px',
  fontWeight: 'bold',
  color: 'grey',
  textAlign: 'center'
}

const totalContainerBody = {
  height: '20px'
}
const totalBody = {
  fontSize: '20px',
  fontWeight: 'bold',
  textAlign: 'center'
}

const totalTitle = {
  fontSize: '20px',
  fontWeight: 'bold',


}

const column = {
  width: '25%',
  height: '15px',
  fontSize: '15px',
  textAlign: 'center',
}

const columnLarge = {
  width: '35%',
  height: '15px',
  fontSize: '15px',
  textAlign: 'center',
}

const columnSmall = {
  width: '15%',
  height: '15px',
  fontSize: '15px',
  textAlign: 'center',
}

const columnSub = {
  width: '25%',
  height: '17px',
  fontSize: '15px',
  textAlign: 'center',
  fontWeight: 'normal',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}

const columnSubLarge = {
  width: '35%',
  height: '17px',
  fontSize: '15px',
  textAlign: 'center',
  fontWeight: 'normal',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}

const columnSubSmall = {
  width: '15%',
  height: '17px',
  fontSize: '15px',
  textAlign: 'center',
  fontWeight: 'normal',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}

const summaryRow = {
  height: '15px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

}

const dropdownMenu = {
  width: '100%',
  height: '60px',

  display: 'inline-flex'
}

const dropdownBox = {
  width: '50%',
  height: '60px',
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  paddingRight: '40px'

}

const titleBox = {
  width: '50%',
  height: '60px',
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  paddingLeft: '40px'

}

const title = {
  fontWeight: 'bold',
  font: '60px',
  textDecoration: 'underline dotted',
}