import React from 'react';
import ReactDOM from "react-dom/client";
import App from './Components/App';
import {Provider} from 'react-redux';
import {createStore} from 'redux';
import {store} from './redux/reducers/globalReducers.js'

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App/>
  </Provider>
);

