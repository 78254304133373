export const updateState = state =>{
    return{
        type:'UPDATE_STATE',
        payload:{
            state: state
        }
    }
}

export const createState = state =>{
    return{
        type:'CREATE_STATE',
        payload:{
            state: state
        }
    }
}

export const deleteState = state =>{
    return{
        type:'DELETE_STATE',
        payload:{
            state
        }
    }
}

export const resetState = state =>{
    return{
        type:'RESET',
        payload:{
            state
        }
    }
}