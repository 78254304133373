
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect, ReactReduxContext, } from 'react-redux';
import patrick from "../assets/patrick.png";
import { createState, updateState, } from '../redux/actions/StateActions.js';
import { State } from '../redux/model/State.js';
import FoodItem from './FoodItem';
import arrowDown from '../assets/down.png'
import arrowUp from '../assets/up.png'
import img_gi_tract from '../assets/gitract.png';
import bristolStoolChart from '../assets/stool-chart.png';
import bristol from '../assets/bristol.png';
import blank_png from '../assets/blank_png.png'

import ModalImage from "react-modal-image";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";


// app component
function HomePage() {

  const dispatch = useDispatch()
  const state = useSelector(state => state.state_reducer[0])
  const linkFiberChart = 'https://www.med.umich.edu/mott/pdf/mott-fiber-chart.pd'

  // component did mount
  useEffect(() => {

    // resize listener
    window.addEventListener("resize", updateSize);

    // unmount component
    return () => {
      window.removeEventListener("resize", updateSize);
    }
  }, [])

  // component did update
  useEffect(() => {

  })

  // reset the window size
  function updateSize() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }

  function setWidth(value) {

    const f = state;
    f.width = value;
    dispatch(updateState(f))

  }

  function setHeight(value) {

    const f = state;
    f.height = value;
    dispatch(updateState(f))

  }


  return (
    <div style={parentContainerStyle}>
      <div style={containerStyle}>
        <div style={infoStyle}>
          <img width={180} src={img_gi_tract} />
        </div>

        <div style={infoTop}>
          <h1>You should aim to get 25-30 grams of fiber per day for optimal gut health</h1>
        </div>

        <Link
          to="/count"
          style={buttonStyle}>
          Count Your Fiber
        </Link>

        <div style={infoLink}>
          <a onClick={() => { window.open(linkFiberChart) }}>
            <h1>
              Fiber Chart Reference
            </h1>
          </a>
        </div>

      </div>
      <div style={{ position: 'absolute', top: '10%', display: 'flex', height: '250px', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <ModalImage
          large={bristolStoolChart}
          small={blank_png}
          alt="Bristol Stool Chart"
        />
      </div>
    </div>
  )

}

export default HomePage;

const buttonStyle = {
  height: '100px', width: '270px',
  display: 'flex', alignItems: 'center', justifyContent: 'center',
  borderRadius: '3px', outline: 'none', border: 'none',
  boxShadow: '0px 2px 3px 2px 0px #FFFFFF',
  backgroundColor: '#CBC3E3',
  fontSize: '30px', fontWeight: 'bold', color: 'white',
  boxShadow: '0px 3px 12px 0px rgba(0,0,0,0.4)',
  textDecoration: 'none'
}

const infoStyle = {
  marginBottom: '20px',
  height: '100px', width: '250px',
  display: 'flex', alignItems: 'center', justifyContent: 'center',
  borderRadius: '3px', outline: 'none', border: 'none',
  boxShadow: '0px 2px 3px 2px 0px #FFFFFF',
  backgroundColor: 'rbg(155,155,155)',
  opacity: 0.3,
  fontSize: '17px', fontWeight: 'bold', color: 'white',
  textAlign: 'center'
}

const infoTop = {
  marginBottom: '20px',
  width: '90%',
  display: 'flex', alignItems: 'center', justifyContent: 'center',
  opacity: 0.3,
  fontSize: '17px', fontWeight: 'bold', color: 'white',
  textAlign: 'center'
}

const infoBottom = {
  marginTop: '30px',
  display: 'flex', alignItems: 'center', justifyContent: 'center',
  textAlign: 'center'
}

const infoBottomText = {
  display: 'flex', alignItems: 'center', justifyContent: 'center',
  opacity: 0.5,
  fontSize: '33px', fontWeight: 'bold', color: 'grey',
  textAlign: 'center',
  marginLeft: '10px'
}

const infoLink = {
  marginTop: '30px',
  display: 'flex', alignItems: 'center', justifyContent: 'center',
  textAlign: 'center',
  opacity: 0.5,
  fontSize: 9, color: 'grey',
  textDecoration: 'underline'
}

const containerStyle = {
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',

}

const parentContainerStyle = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0, left: 0,
  overflow: 'hidden',
  backgroundImage: 'linear-gradient(purple, yellow)',
  fontFamily: 'Arial',
}