import { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect,} from 'react-redux';
import {createState, updateState,} from '../redux/actions/StateActions.js';
import {State} from '../redux/model/State.js';
import FibreCounter from './FibreCounter.js';
import HomePage from './HomePage.js';
import { BrowserRouter, Routes, Route } from "react-router-dom";

// app component
function App(props){

  const {state, createState } = props
  
  // component did mount
  useEffect(()=>{

    // add redux state and initialize values
    const initState = new State();
    initState.width = window.innerWidth;
    initState.height = window.innerHeight;
    initState.foodItems = [
      {food:'Apple', size:'medium', multiplier:1, options: [{size:'small', multiplier: 0.75}, {size:'medium', multiplier: 1}, {size:'large', multiplier: 1.25}], count: 0, fiber: 3.7, type:'fruit'},
      {food:'Apple (Skinless)', size:'medium', multiplier:1, options: [{size:'small', multiplier: 0.75}, {size:'medium', multiplier: 1}, {size:'large', multiplier: 1.25}], count: 0, fiber: 2.4, type:'fruit'},
      {food:'Applesauce', size:'1/2 cup', multiplier:1, options: [{size:'1/4 cup', multiplier: 0.5}, {size:'1/2 cup', multiplier: 1}, {size:'3/4 cup', multiplier: 1.25}], count: 0, fiber: 2.0, type:'fruit'},
      {food:'Apricot', size:'medium', multiplier:1, options: [{size:'small', multiplier: 0.75}, {size:'medium', multiplier: 1}, {size:'large', multiplier: 1.25}], count: 0, fiber: 0.83, type:'fruit'},
      {food:'Banana', size:'medium', multiplier:1, options: [{size:'small', multiplier: 0.75}, {size:'medium', multiplier: 1}, {size:'large', multiplier: 1.25}], count: 0, fiber: 2.7, type:'fruit'},
      {food:'Blueberries', size:'1 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.5}, {size:'1 cup', multiplier: 1}, {size:'1.5 cups', multiplier: 1.5}], count: 0, fiber: 4.0, type:'fruit'},
      {food:'Cantaloup', size:'1 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.5}, {size:'1 cup', multiplier: 1}, {size:'1.5 cups', multiplier: 1.5}], count: 0, fiber: 1.3, type:'fruit'},
      {food:'Cherry', size:'medium', multiplier:1, options: [{size:'small', multiplier: 0.75}, {size:'medium', multiplier: 1}, {size:'large', multiplier: 1.25}], count: 0, fiber: 0.13, type:'fruit'},
      {food:'Fruit Salad', size:'1/2 cup', multiplier:1, options: [{size:'1/4 cup', multiplier: 0.5}, {size:'1/2 cup', multiplier: 1}, {size:'3/4 cup', multiplier: 1.25}], count: 0, fiber: 1.3, type:'fruit'},
      {food:'Grapefruit', size:'1/2 medium', multiplier:1, options: [{size:'1/2 small', multiplier: 0.75}, {size:'1/2 medium', multiplier: 1}, {size:' 1/2 large', multiplier: 1.25}], count: 0, fiber: 1.3, type:'fruit'},
      {food:'Grapes', size:'1 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.5}, {size:'1 cup', multiplier: 1}, {size:'1.5 cups', multiplier: 1.5}], count: 0, fiber: 1.2, type:'fruit'},
      {food:'Honeydew', size:'1 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.5}, {size:'1 cup', multiplier: 1}, {size:'1.5 cups', multiplier: 1.5}], count: 0, fiber: 1.0, type:'fruit'},
      {food:'Mandarin Orange', size:'1/2 cup', multiplier:1, options: [{size:'1/4 cup', multiplier: 0.5}, {size:'1/2 cup', multiplier: 1}, {size:'3/4 cup', multiplier: 1.25}], count: 0, fiber: 1.0, type:'fruit'},
      {food:'Nectarine', size:'medium', multiplier:1, options: [{size:'small', multiplier: 0.75}, {size:'medium', multiplier: 1}, {size:'large', multiplier: 1.25}], count: 0, fiber: 2.2, type:'fruit'},
      {food:'Orange', size:'medium', multiplier:1, options: [{size:'small', multiplier: 0.75}, {size:'medium', multiplier: 1}, {size:'large', multiplier: 1.25}], count: 0, fiber: 3.0, type:'fruit'},
      {food:'Peach', size:'medium', multiplier:1, options: [{size:'small', multiplier: 0.75}, {size:'medium', multiplier: 1}, {size:'large', multiplier: 1.25}], count: 0, fiber: 1.7, type:'fruit'},
      {food:'Pear', size:'medium', multiplier:1, options: [{size:'small', multiplier: 0.75}, {size:'medium', multiplier: 1}, {size:'large', multiplier: 1.25}], count: 0, fiber: 4.0, type:'fruit'},
      {food:'Pineapple', size:'1 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.5}, {size:'1 cup', multiplier: 1}, {size:'1.5 cups', multiplier: 1.5}], count: 0, fiber: 2.0, type:'fruit'},
      {food:'Plum', size:'medium', multiplier:1, options: [{size:'small', multiplier: 0.75}, {size:'medium', multiplier: 1}, {size:'large', multiplier: 1.25}], count: 0, fiber: 1.0, type:'fruit'},
      {food:'Prune (Dried)', size:'medium', multiplier:1, options: [{size:'small', multiplier: 0.75}, {size:'medium', multiplier: 1}, {size:'large', multiplier: 1.25}], count: 0, fiber: 0.6, type:'fruit'},
      {food:'Raisins', size:'2/3 cup', multiplier:1, options: [{size:'1/3 cup', multiplier: 0.5}, {size:'2/3 cup', multiplier: 1}, {size:'1 cup', multiplier: 1.5}], count: 0, fiber: 4.0, type:'fruit'},
      {food:'Raspberries', size:'1 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.5}, {size:'1 cup', multiplier: 1}, {size:'1.5 cups', multiplier: 1.5}], count: 0, fiber: 8.4, type:'fruit'},
      {food:'Strawberries', size:'1 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.5}, {size:'1 cup', multiplier: 1}, {size:'1.5 cups', multiplier: 1.5}], count: 0, fiber: 3.4, type:'fruit'},
      {food:'Tangerine', size:'medium', multiplier:1, options: [{size:'small', multiplier: 0.75}, {size:'medium', multiplier: 1}, {size:'large', multiplier: 1.25}], count: 0, fiber: 2.0, type:'fruit'},
      {food:'Watermelon', size:'1 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.5}, {size:'1 cup', multiplier: 1}, {size:'1.5 cups', multiplier: 1.5}], count: 0, fiber: 0.8, type:'fruit'},

      {food:'Artichoke', size:'medium', multiplier:1, options: [{size:'small', multiplier: 0.75}, {size:'medium', multiplier: 1}, {size:'large', multiplier: 1.25}], count: 0, fiber: 6.2, type:'vegetable'},
      {food:'Asparagus', size:'medium', multiplier:1, options: [{size:'small', multiplier: 0.75}, {size:'medium', multiplier: 1}, {size:'large', multiplier: 1.25}], count: 0, fiber: 0.23, type:'vegetable'},
      {food:'Baked Beans', size:'1 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.5}, {size:'1 cup', multiplier: 1}, {size:'1.5 cups', multiplier: 1.5}], count: 0, fiber: 14, type:'vegetable'},
      {food:'Broccoli', size:'1/2 cup', multiplier:1, options: [{size:'1/4 cup', multiplier: 0.5}, {size:'1/2 cup', multiplier: 1}, {size:'3/4 cup', multiplier: 1.25}], count: 0, fiber: 2.3, type:'vegetable'},
      {food:'Brussel Sprouts', size:'1/2 cup', multiplier:1, options: [{size:'1/4 cup', multiplier: 0.5}, {size:'1/2 cup', multiplier: 1}, {size:'3/4 cup', multiplier: 1.25}], count: 0, fiber: 2.0, type:'vegetable'},
      {food:'Carrot', size:'medium', multiplier:1, options: [{size:'small', multiplier: 0.75}, {size:'medium', multiplier: 1}, {size:'large', multiplier: 1.25}], count: 0, fiber: 2.0, type:'vegetable'},
      {food:'Cauliflower', size:'1/2 cup', multiplier:1, options: [{size:'1/4 cup', multiplier: 0.5}, {size:'1/2 cup', multiplier: 1}, {size:'3/4 cup', multiplier: 1.25}], count: 0, fiber: 1.7, type:'vegetable'},
      {food:'Celery Stalk', size:'medium', multiplier:1, options: [{size:'small', multiplier: 0.75}, {size:'medium', multiplier: 1}, {size:'large', multiplier: 1.25}], count: 0, fiber: 0.7, type:'vegetable'},
      {food:'Coleslaw', size:'1/2 cup', multiplier:1, options: [{size:'1/4 cup', multiplier: 0.5}, {size:'1/2 cup', multiplier: 1}, {size:'3/4 cup', multiplier: 1.25}], count: 0, fiber: 1.0, type:'vegetable'},
      {food:'Corn on the Cobb', size:'medium', multiplier:1, options: [{size:'small', multiplier: 0.75}, {size:'medium', multiplier: 1}, {size:'large', multiplier: 1.25}], count: 0, fiber: 2.0, type:'vegetable'},
      {food:'Cucumber', size:'1/2 cup', multiplier:1, options: [{size:'1/4 cup', multiplier: 0.5}, {size:'1/2 cup', multiplier: 1}, {size:'3/4 cup', multiplier: 1.25}], count: 0, fiber: 0.5, type:'vegetable'},
      {food:'Eggplant', size:'1/2 cup', multiplier:1, options: [{size:'1/4 cup', multiplier: 0.5}, {size:'1/2 cup', multiplier: 1}, {size:'3/4 cup', multiplier: 1.25}], count: 0, fiber: 1.0, type:'vegetable'},
      {food:'Green Beans', size:'1/2 cup', multiplier:1, options: [{size:'1/4 cup', multiplier: 0.5}, {size:'1/2 cup', multiplier: 1}, {size:'3/4 cup', multiplier: 1.25}], count: 0, fiber: 2.0, type:'vegetable'},
      {food:'Lima Beans', size:'1 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.5}, {size:'1 cup', multiplier: 1}, {size:'1.5 cups', multiplier: 1.5}], count: 0, fiber: 13.2, type:'vegetable'},
      {food:'Lettuce', size:'1/2 cup', multiplier:1, options: [{size:'1/4 cup', multiplier: 0.5}, {size:'1/2 cup', multiplier: 1}, {size:'3/4 cup', multiplier: 1.25}], count: 0, fiber: 0.5, type:'vegetable'},
      {food:'Lentils & Split Peas', size:'1 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.5}, {size:'1 cup', multiplier: 1}, {size:'1.5 cups', multiplier: 1.5}], count: 0, fiber: 15.5, type:'vegetable'},
      {food:'Mushrooms', size:'1/2 cup', multiplier:1, options: [{size:'1/4 cup', multiplier: 0.5}, {size:'1/2 cup', multiplier: 1}, {size:'3/4 cup', multiplier: 1.25}], count: 0, fiber: 0.4, type:'vegetable'},
      {food:'Onions', size:'1/2 cup', multiplier:1, options: [{size:'1/4 cup', multiplier: 0.5}, {size:'1/2 cup', multiplier: 1}, {size:'3/4 cup', multiplier: 1.25}], count: 0, fiber: 1.0, type:'vegetable'},
      {food:'Green Peas', size:'1/2 cup', multiplier:1, options: [{size:'1/4 cup', multiplier: 0.5}, {size:'1/2 cup', multiplier: 1}, {size:'3/4 cup', multiplier: 1.25}], count: 0, fiber: 4.0, type:'vegetable'},
      {food:'Pinto Beans', size:'1 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.5}, {size:'1 cup', multiplier: 1}, {size:'1.5 cups', multiplier: 1.5}], count: 0, fiber: 14.7, type:'vegetable'},
      {food:'Baked Potato', size:'medium', multiplier:1, options: [{size:'small', multiplier: 0.75}, {size:'medium', multiplier: 1}, {size:'large', multiplier: 1.25}], count: 0, fiber: 5.0, type:'vegetable'},
      {food:'Boiled Potato', size:'medium', multiplier:1, options: [{size:'small', multiplier: 0.75}, {size:'medium', multiplier: 1}, {size:'large', multiplier: 1.25}], count: 0, fiber: 2.0, type:'vegetable'},
      {food:'Potato Salad', size:'1/2 cup', multiplier:1, options: [{size:'1/4 cup', multiplier: 0.5}, {size:'1/2 cup', multiplier: 1}, {size:'3/4 cup', multiplier: 1.25}], count: 0, fiber: 1.6, type:'vegetable'},
      {food:'Pumpkin', size:'1/2 cup', multiplier:1, options: [{size:'1/4 cup', multiplier: 0.5}, {size:'1/2 cup', multiplier: 1}, {size:'3/4 cup', multiplier: 1.25}], count: 0, fiber: 5.0, type:'vegetable'},
      {food:'Boiled Spinach', size:'1/2 cup', multiplier:1, options: [{size:'1/4 cup', multiplier: 0.5}, {size:'1/2 cup', multiplier: 1}, {size:'3/4 cup', multiplier: 1.25}], count: 0, fiber: 2.2, type:'vegetable'},
      {food:'Raw Spinach', size:'1/2 cup', multiplier:1, options: [{size:'1/4 cup', multiplier: 0.5}, {size:'1/2 cup', multiplier: 1}, {size:'3/4 cup', multiplier: 1.25}], count: 0, fiber: 0.8, type:'vegetable'},
      {food:'Squash (Winter)', size:'1/2 cup', multiplier:1, options: [{size:'1/4 cup', multiplier: 0.5}, {size:'1/2 cup', multiplier: 1}, {size:'3/4 cup', multiplier: 1.25}], count: 0, fiber: 3.0, type:'vegetable'},
      {food:'Sweet Potato', size:'medium', multiplier:1, options: [{size:'small', multiplier: 0.75}, {size:'medium', multiplier: 1}, {size:'large', multiplier: 1.25}], count: 0, fiber: 3.0, type:'vegetable'},
      {food:'Tomato', size:'medium', multiplier:1, options: [{size:'small', multiplier: 0.75}, {size:'medium', multiplier: 1}, {size:'large', multiplier: 1.25}], count: 0, fiber: 1.0, type:'vegetable'},
    
      {food:'All-Bran', size:'1/2 cup', multiplier:1, options: [{size:'1/4 cup', multiplier: 0.5}, {size:'1/2 cup', multiplier: 1}, {size:'3/4 cup', multiplier: 1.25}], count: 0, fiber: 10.0, type:'cereal'},
      {food:'Alpha Bits', size:'1 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.5}, {size:'1 cup', multiplier: 1}, {size:'1.5 cups', multiplier: 1.5}], count: 0, fiber: 1.0, type:'cereal'},
      {food:'Banana Nut Crunch', size:'1 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.5}, {size:'1 cup', multiplier: 1}, {size:'1.5 cups', multiplier: 1.5}], count: 0, fiber: 4.0, type:'cereal'},
      {food:'Bran-Buds', size:'2/3 cup', multiplier:1, options: [{size:'1/3 cup', multiplier: 0.66}, {size:'2/3 cup', multiplier: 1}, {size:'1 cup', multiplier: 1.33}], count: 0, fiber: 18.2, type:'cereal'},
      {food:'Cheerios', size:'1 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.5}, {size:'1 cup', multiplier: 1}, {size:'1.5 cups', multiplier: 1.5}], count: 0, fiber: 3.0, type:'cereal'},
      {food:'Corn Pops', size:'1 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.5}, {size:'1 cup', multiplier: 1}, {size:'1.5 cups', multiplier: 1.5}], count: 0, fiber: 0.0, type:'cereal'},
      {food:'Cracklin Oat Bran', size:'3/4 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.66}, {size:'3/4 cup', multiplier: 1}, {size:'1 cup', multiplier: 1.33}], count: 0, fiber: 5.6, type:'cereal'},
      {food:'Cream Of Wheat', size:'1 pack', multiplier:1, options: [{size:'1/2 pack', multiplier: 0.5}, {size:'1 pack', multiplier: 1}, {size:'1.5 packs', multiplier: 1.5}], count: 0, fiber: 1.0, type:'cereal'},
      {food:'Fiber One', size:'1/2 cup', multiplier:1, options: [{size:'1/4 cup', multiplier: 0.5}, {size:'1/2 cup', multiplier: 1}, {size:'3/4 cup', multiplier: 1.25}], count: 0, fiber: 13.0, type:'cereal'},
      {food:'Frosted Mini Wheats', size:'5 biscuits', multiplier:1, options: [{size:'3 biscuits', multiplier: 0.6}, {size:'5 biscuits', multiplier: 1}, {size:'7 biscuits', multiplier: 1.4}], count: 0, fiber: 5.0, type:'cereal'},
      {food:'Honey Nut Cheerios', size:'1 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.5}, {size:'1 cup', multiplier: 1}, {size:'1.5 cups', multiplier: 1.5}], count: 0, fiber: 2.0, type:'cereal'},
      {food:'Instant Oatmeal', size:'1 pack', multiplier:1, options: [{size:'1/2 pack', multiplier: 0.5}, {size:'1 pack', multiplier: 1}, {size:'1.5 packs', multiplier: 1.5}], count: 0, fiber: 3.0, type:'cereal'},
      {food:'Multigrain Cheerios', size:'1 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.5}, {size:'1 cup', multiplier: 1}, {size:'1.5 cups', multiplier: 1.5}], count: 0, fiber: 3.0, type:'cereal'},
      {food:'Shredded Wheat', size:'3 biscuits', multiplier:1, options: [{size:'1 biscuit', multiplier: 0.33}, {size:'3 biscuits', multiplier: 1}, {size:'5 biscuits', multiplier: 1.66}], count: 0, fiber: 7.3, type:'cereal'},
      {food:'Raisin Bran (GM)', size:'3/4 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.66}, {size:'3/4 cup', multiplier: 1}, {size:'1 cup', multiplier: 1.33}], count: 0, fiber: 3.0, type:'cereal'},
      {food:'Raisin Bran (Kellogs)', size:'1 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.5}, {size:'1 cup', multiplier: 1}, {size:'1.5 cups', multiplier: 1.5}], count: 0, fiber: 8.2, type:'cereal'},

      {food:'Artisan Slice', size:'medium (1/2")', multiplier:1, options: [{size:'thin (1/4")', multiplier: 0.5}, {size:'medium (1/2")', multiplier: 1}, {size:'thick (1")', multiplier: 2}], count: 0, fiber: 1.0, type:'breads'},
      {food:'Bagel', size:'1 bagel', multiplier:1, options: [{size:'1/2 bagel', multiplier: 0.5}, {size:'1 bagel', multiplier: 1}, {size:'1.5 bagels', multiplier: 1.5}], count: 0, fiber: 1.5, type:'breads'},
      {food:'English Muffin', size:'1 muffin', multiplier:1, options: [{size:'1/2 muffin', multiplier: 0.5}, {size:'1 muffin', multiplier: 1}, {size:'1.5 muffins', multiplier: 1.5}], count: 0, fiber: 1.5, type:'breads'},
      {food:'Baguette Slice', size:'medium (1")', multiplier:1, options: [{size:'thin (1/2")', multiplier: 0.5}, {size:'medium (1")', multiplier: 1}, {size:'thick (1.5")', multiplier: 1.5}], count: 0, fiber: 0.35, type:'breads'},
      {food:'Baguette Roll', size:'medium (6")', multiplier:1, options: [{size:'small (4")', multiplier: 0.66}, {size:'medium (6")', multiplier: 1}, {size:'large (8")', multiplier: 1.33}], count: 0, fiber: 2.3, type:'breads'},
      {food:'Multi Grain', size:'1 slice', multiplier:1, options: [{size:'1/2 slice', multiplier: 0.5}, {size:'1 slice', multiplier: 1}, {size:'1.5 slices', multiplier: 1.5}], count: 0, fiber: 1.5, type:'breads'},
      {food:'White Pancakes', size:'medium', multiplier:1, options: [{size:'small', multiplier: 0.5}, {size:'medium', multiplier: 1}, {size:'large', multiplier: 1.5}], count: 0, fiber: 1.0, type:'breads'},
      {food:'Whole Wheat Pancakes', size:'medium', multiplier:1, options: [{size:'small', multiplier: 0.5}, {size:'medium', multiplier: 1}, {size:'large', multiplier: 1.5}], count: 0, fiber: 3.0, type:'breads'},
      {food:'White Pita', size:'medium (6")', multiplier:1, options: [{size:'small (4")', multiplier: 0.66}, {size:'medium (6")', multiplier: 1}, {size:'large (9")', multiplier: 1.33}], count: 0, fiber: 1.0, type:'breads'},
      {food:'Whole Wheat Pita', size:'medium (6")', multiplier:1, options: [{size:'small (4")', multiplier: 0.66}, {size:'medium (6")', multiplier: 1}, {size:'large (9")', multiplier: 1.33}], count: 0, fiber: 3.0, type:'breads'},
      {food:'Seven Grain', size:'1 slice', multiplier:1, options: [{size:'1/2 slice', multiplier: 0.5}, {size:'1 slice', multiplier: 1}, {size:'1.5 slices', multiplier: 1.5}], count: 0, fiber: 3.0, type:'breads'},
      {food:'Whole Wheat', size:'1 slice', multiplier:1, options: [{size:'1/2 slice', multiplier: 0.5}, {size:'1 slice', multiplier: 1}, {size:'1.5 slices', multiplier: 1.5}], count: 0, fiber: 2.0, type:'breads'},
      {food:'White', size:'1 slice', multiplier:1, options: [{size:'1/2 slice', multiplier: 0.5}, {size:'1 slice', multiplier: 1}, {size:'1.5 slices', multiplier: 1.5}], count: 0, fiber: 1.0, type:'breads'},

      {food:'Macaroni', size:'1 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.5}, {size:'1 cup', multiplier: 1}, {size:'1.5 cups', multiplier: 1.5}], count: 0, fiber: 1.8, type:'pasta'},
      {food:'Macaroni Whole Wheat', size:'1 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.5}, {size:'1 cup', multiplier: 1}, {size:'1.5 cups', multiplier: 1.5}], count: 0, fiber: 4.0, type:'pasta'},
      {food:'Penne', size:'1 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.5}, {size:'1 cup', multiplier: 1}, {size:'1.5 cups', multiplier: 1.5}], count: 0, fiber: 2.7, type:'pasta'},
      {food:'Penne Whole Wheat', size:'1 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.5}, {size:'1 cup', multiplier: 1}, {size:'1.5 cups', multiplier: 1.5}], count: 0, fiber: 3.8, type:'pasta'},
      {food:'Rigatoni', size:'1 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.5}, {size:'1 cup', multiplier: 1}, {size:'1.5 cups', multiplier: 1.5}], count: 0, fiber: 1.8, type:'pasta'},
      {food:'Rigatoni Whole Wheat', size:'1 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.5}, {size:'1 cup', multiplier: 1}, {size:'1.5 cups', multiplier: 1.5}], count: 0, fiber: 4.0, type:'pasta'},
      {food:'Spagehtti', size:'1 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.5}, {size:'1 cup', multiplier: 1}, {size:'1.5 cups', multiplier: 1.5}], count: 0, fiber: 2.5, type:'pasta'},
      {food:'Spagehtti Whole Wheat', size:'1 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.5}, {size:'1 cup', multiplier: 1}, {size:'1.5 cups', multiplier: 1.5}], count: 0, fiber: 6.3, type:'pasta'},
      {food:'Rice', size:'1 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.5}, {size:'1 cup', multiplier: 1}, {size:'1.5 cups', multiplier: 1.5}], count: 0, fiber: 1.0, type:'pasta'},
      {food:'Brown Rice', size:'1 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.5}, {size:'1 cup', multiplier: 1}, {size:'1.5 cups', multiplier: 1.5}], count: 0, fiber: 3.5, type:'pasta'},
    
      {food:'Almonds', size:'1 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.5}, {size:'1 cup', multiplier: 1}, {size:'1.5 cups', multiplier: 1.5}], count: 0, fiber: 12, type:'nuts'},
      {food:'Chia Seeds', size:'1 Oz', multiplier:1, options: [{size:'1 Oz', multiplier: 1}, {size:'1/3 cup', multiplier: 2.64}, {size:'1 tablespoon', multiplier: 0.5}], count: 0, fiber: 10, type:'nuts'},
      {food:'Cashews', size:'1 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.5}, {size:'1 cup', multiplier: 1}, {size:'1.5 cups', multiplier: 1.5}], count: 0, fiber: 4.2, type:'nuts'},
      {food:'Peanuts', size:'1 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.5}, {size:'1 cup', multiplier: 1}, {size:'1.5 cups', multiplier: 1.5}], count: 0, fiber: 12, type:'nuts'},
      {food:'Pistachios', size:'1 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.5}, {size:'1 cup', multiplier: 1}, {size:'1.5 cups', multiplier: 1.5}], count: 0, fiber: 13, type:'nuts'},
      {food:'Sunflower Seeds', size:'1 cup', multiplier:1, options: [{size:'1/2 cup', multiplier: 0.5}, {size:'1 cup', multiplier: 1}, {size:'1.5 cups', multiplier: 1.5}], count: 0, fiber: 12, type:'nuts'},
      

    ];
    
    createState(initState)

    document.title = "Fiber Counter"
    return () => {
      // unmount component
    }
  },[])

  // component did update
  useEffect(()=>{
 
  })

  return(
    <BrowserRouter>
      <Routes>
          <Route index element={<HomePage/>} />
          <Route path="count" exact element={ <FibreCounter/>} />
      </Routes>
    </BrowserRouter>
  )
  
}

const mapStateToProps = (state) =>{

  return{
    frame: state.state_reducer,
  };
}

export default connect(mapStateToProps, {
  createState,
  })(App);

const buttonStyle = {
  height:'100px', width:'250px', 
  display:'flex', alignItems:'center', justifyContent:'center',
  borderRadius:'3px', outline:'none', border:'none',
  boxShadow:'0px 2px 3px 2px 0px #FFFFFF',
  backgroundColor:'#CBC3E3',
  fontSize:'30px', fontWeight:'bold', color:'white',
  boxShadow: '0px 3px 12px 0px rgba(0,0,0,0.4)'
}

const infoStyle = {
  marginBottom:'20px',
  height:'100px', width:'250px', 
  display:'flex', alignItems:'center', justifyContent:'center',
  borderRadius:'3px', outline:'none', border:'none',
  boxShadow:'0px 2px 3px 2px 0px #FFFFFF',
  backgroundColor:'rbg(155,155,155)',
  opacity:0.3,
  fontSize:'17px', fontWeight:'bold', color:'white',
  textAlign:'center'
}

const infoTop = {
  marginBottom:'20px',
  width:'90%', 
  display:'flex', alignItems:'center', justifyContent:'center',
  opacity:0.3,
  fontSize:'17px', fontWeight:'bold', color:'white',
  textAlign:'center'
}

const infoBottom = {
  marginTop:'30px',
  display:'flex', alignItems:'center', justifyContent:'center',
  textAlign:'center'
}

const infoBottomText = {
  display:'flex', alignItems:'center', justifyContent:'center',
  opacity:0.5,
  fontSize:'33px', fontWeight:'bold', color:'grey',
  textAlign:'center',
  marginLeft:'10px'
}

const infoLink = {
  marginTop:'30px',
  display:'flex', alignItems:'center', justifyContent:'center',
  textAlign:'center',
  opacity:0.5,
  fontSize:9, color:'grey',
  textDecoration:'underline'
}

const containerStyle = {
  height:'100%', 
  width:'100%', 
  display:'flex', 
  alignItems:'center', 
  justifyContent:'center',
  flexDirection:'column',

}

const parentContainerStyle = {
  width:'100%', 
  height:'100%', 
  position:'absolute', 
  top:0, left:0, 
  overflow:'hidden', 
  backgroundImage:'linear-gradient(purple, yellow)',
  fontFamily:'Arial',
}