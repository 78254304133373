import {State} from '../model/State'

export const state_reducer = (oldStateList=[],action) =>{
    if(action.type === "CREATE_STATE"){
        
        return [...oldStateList,action.payload.state]

    }else if(action.type === "DELETE_STATE"){
        
        oldStateList = oldStateList.filter((ele,index)=>action.payload.state.id !== ele.id);
        return oldStateList
        
    }else if(action.type === "RESET"){
       
        oldStateList = []
        return [...oldStateList]

    }else if(action.type === 'UPDATE_STATE'){
        oldStateList[0] = action.payload.state
        return [...oldStateList]
    }
    return oldStateList
}
