
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect, ReactReduxContext, } from 'react-redux';
import patrick from "../assets/patrick.png";
import { createState, updateState, } from '../redux/actions/StateActions.js';
import { State } from '../redux/model/State.js';

import img_plus from '../assets/plus.png'
import img_minus from '../assets/minus.png'
import img_patrick from '../assets/patrick.png'
import img_burger from '../assets/burger.png'
import img_apple from '../assets/apple-skin.png'
import img_pear from '../assets/pear.png'
import img_banana from '../assets/banana.png'
import img_cherries from '../assets/cherries.png'
import img_blueberries from '../assets/blueberries.png'

import img_apple_skinless from '../assets/apple-skinless.png'
import img_applesauce from '../assets/applesauce.png'
import img_apricot from '../assets/apricot.png'
import img_cantaloup from '../assets/cantaloupe.png'
import img_fruit_salad from '../assets/fruit-salad.png'
import img_grapefruit from '../assets/grapefruit.png'
import img_grapes from '../assets/grapes.png'
import img_honeydew from '../assets/honeydew.png'
import img_mandarin from '../assets/mandarin.png'
import img_nectarine from '../assets/nectarine.png'
import img_orange from '../assets/orange.png'
import img_peach from '../assets/peach.png'
import img_pineapple from '../assets/pineapple.png'
import img_plum from '../assets/plum.png'
import img_prune from '../assets/prune.png'
import img_raisins from '../assets/raisins.png'
import img_raspberries from '../assets/raspberries.png'
import img_strawberries from '../assets/strawberries.png'
import img_tangerine from '../assets/tangerine.png'
import img_watermelon from '../assets/watermelon.png'

import img_artichoke from '../assets/artichoke.png'
import img_asparagus from '../assets/asparagus.png'
import img_bakedbeans from '../assets/bakedbeans.png'
import img_broccoli from '../assets/broccoli.png'
import img_brussels from '../assets/brussels.png'
import img_carrot from '../assets/carrot.png'
import img_cauliflower from '../assets/cauliflower.png'
import img_celery from '../assets/celery.png'
import img_coleslaw from '../assets/coleslaw.png'
import img_corn from '../assets/corn.png'
import img_cucumber from '../assets/cucumber.png'
import img_eggplant from '../assets/eggplant.png'
import img_greenbeans from '../assets/greenbeans.png'
import img_limabeans from '../assets/limabeans.png'
import img_lettuce from '../assets/lettuce.png'
import img_mushroom from '../assets/mushroom.png'
import img_onion from '../assets/onion.png'
import img_peas from '../assets/peas.png'
import img_pinto from '../assets/pinto.png'
import img_bakedpotato from '../assets/bakedpotato.png'
import img_potato from '../assets/boiledpotato.png'
import img_potatosalad from '../assets/potatosalad.png'
import img_pumpkin from '../assets/pumpkin.png'
import img_spinachboiled from '../assets/boiledspinach.png'
import img_spinach from '../assets/spinach.png'
import img_squash from '../assets/squash.png'
import img_sweetpotato from '../assets/sweetpotato.png'
import img_tomato from '../assets/tomato.png'


// app component
function FoodItem({ type, size, count, fiber, options }) {

  const dispatch = useDispatch()
  const state = useSelector(state => state.state_reducer[0])

  function increaseCounter(type) {
    const s = state
    for (let i = 0; i < s.foodItems.length; i++) {
      if (s.foodItems[i].food === type) {
        s.foodItems[i].count = count + 1;
      }
    }
    dispatch(updateState(s))
  }

  function decreaseCounter() {
    const s = state
    for (let i = 0; i < s.foodItems.length; i++) {
      if (s.foodItems[i].food === type && s.foodItems[i].count > 0) {
        s.foodItems[i].count = count - 1;
      }
    }
    dispatch(updateState(s))
  }

  function switchImagePath(type) {

    switch (type) {
      case 'Apple (Skinless)':
        return img_apple_skinless;
        break;
      case 'Applesauce':
        return img_applesauce;
        break;
      case 'Apricot':
        return img_apricot;
        break;
      case 'Cantaloup':
        return img_cantaloup;
        break;
      case 'Fruit Salad':
        return img_fruit_salad;
        break;
      case 'Grapefruit':
        return img_grapefruit;
        break;
      case 'Grapes':
        return img_grapes;
        break;
      case 'Honeydew':
        return img_honeydew;
        break;
      case 'Mandarin Orange':
        return img_mandarin;
        break;
      case 'Nectarine':
        return img_nectarine;
        break;
      case 'Orange':
        return img_orange;
        break;
      case 'Peach':
        return img_peach;
        break;
      case 'Pineapple':
        return img_pineapple;
        break;
      case 'Plum':
        return img_plum;
        break;
      case 'Prune (Dried)':
        return img_prune;
        break;
      case 'Raisins':
        return img_raisins;
        break;
      case 'Raspberries':
        return img_raspberries;
        break;
      case 'Strawberries':
        return img_strawberries;
        break;
      case 'Tangerine':
        return img_tangerine;
        break;
      case 'Watermelon':
        return img_watermelon;
        break;
      case 'Patrick':
        return img_patrick;
        break;
      case 'Burger':
        return img_burger;
        break;
      case 'Apple':
        return img_apple;
        break;
      case 'Pear':
        return img_pear;
        break;
      case 'Cherry':
        return img_cherries;
        break;
      case 'Blueberries':
        return img_blueberries;
        break;
      case 'Banana':
        return img_banana;
        break;
      case 'Artichoke':
        return img_artichoke;
        break;
      case 'Asparagus':
        return img_asparagus;
        break;
      case 'Baked Beans':
        return img_bakedbeans;
        break;
      case 'Broccoli':
        return img_broccoli;
        break;
      case 'Brussel Sprouts':
        return img_brussels;
        break;
      case 'Carrot':
        return img_carrot;
        break;
      case 'Cauliflower':
        return img_cauliflower;
        break;
        case 'Coleslaw':
          return img_coleslaw;
          break;
      case 'Celery Stalk':
        return img_celery;
        break;
      case 'Corn on the Cobb':
        return img_corn;
        break;
      case 'Cucumber':
        return img_cucumber;
        break;
      case 'Eggplant':
        return img_eggplant;
        break;
      case 'Green Beans':
        return img_greenbeans;
        break;
      case 'Lima Beans':
        return img_limabeans;
        break;
      case 'Lettuce':
        return img_lettuce;
        break;
      case 'Mushrooms':
        return img_mushroom;
        break;
      case 'Onions':
        return img_onion;
        break;
      case 'Green Peas':
        return img_peas;
        break;
      case 'Pinto Beans':
        return img_pinto;
        break;
      case 'Baked Potato':
        return img_bakedpotato;
        break;
      case 'Boiled Potato':
        return img_potato;
        break;
      case 'Potato Salad':
        return img_potatosalad;
        break;
      case 'Pumpkin':
        return img_pumpkin;
        break;
      case 'Boiled Spinach':
        return img_spinachboiled;
        break;
      case 'Raw Spinach':
        return img_spinach;
        break;
      case 'Squash (Winter)':
        return img_squash;
        break;
      case 'Sweet Potato':
        return img_sweetpotato;
        break;
      case 'Tomato':
        return img_tomato;
        break;
      default:
        return img_burger;
        break;
    }
  }

  function setDropdown(e){

    const s = state
    for (let i = 0; i < s.foodItems.length; i++) {
      if (s.foodItems[i].food === type) {
        s.foodItems[i].size = e.target.value;
      }
    }
    dispatch(updateState(s))
  }

  function getOptions(){

    return options.map((o,i)=>{
      return(
      <option key={i} value={o.size}>
        {o.size}
      </option>
      )
    })
  }

  function calculateFiber(){

    var multiplier;
    options.forEach(option => {
      if(option.size == size){
        multiplier = option.multiplier
      }
    });
    var value = fiber*multiplier
    return parseFloat(value.toFixed(2))
  }

  return (
    
    <div style={count > 0 ? greyParentContainerStyle : parentContainerStyle}>

      <div style={imageText}>
        <img style={imageStyle} src={switchImagePath(type)} />
        <h1 style={title}>
          {type}
        </h1>
      </div>

      <div style={plusMinus}>
        <button style={minus} onClick={() => { increaseCounter(type) }}>
          <img src={img_plus} width={20} />
        </button>

        <button style={minus} onClick={() => { decreaseCounter(type) }}>
          <img src={img_minus} width={20} />
        </button>
      </div>
 
      <div style={counterContainer}>
       
      <div style={counter}>
        <h1 style={countText}>{count}</h1>
      </div>
        <div style={dropdownTitleContainer}>
          <div style={dropdownMenuContainer}>
            <select style={dropdownMenu} value={size} onChange={(event)=>setDropdown(event)}>
              {getOptions()}
            </select>
          </div>
          <h1 style={titleFiber}>
            {calculateFiber()} g of fiber
          </h1>
        </div>
      </div>

    </div>
  )

}

export default FoodItem;

const parentContainerStyle = {
  width: '100%',
  height: '180px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '20px',
  
}

const greyParentContainerStyle = {
  width: '100%',
  height: '180px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '20px',
  backgroundColor: 'lightgrey'
}

const imageText = {
  marginTop: '10px',
  height: '170px',
  width: '120px',
  maxWidth:'120px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
 
}

const imageStyle = {
  maxWidth: '120px',
  maxHeight: '120px'
}

const title = {
  fontSize: '20px',
  fontWeight: 'bold',
  textAlign: 'center',
  width:'120px'
}

const plusMinus = {
  marginTop: '10px',
  marginLeft:'10px',
  marginRight:'10px',
  height: '170px',
  width:'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
}

const minus = {
  minWidth:'90px',
  width:'auto',
  height: '70px',
  marginBottom: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#05b508',
  border: 'none',
  outline: 'none',
  borderRadius: '5px',
  fontWeight: 'bold',
  fontSize: '23px',
  boxShadow: '0px 3px 12px 0px rgba(0,0,0,0.4)'
}

const counterContainer = {
  //paddingTop: '-20px',
  height: '170px',
  width:'140px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
}

const counter = {
  width: '120px',
  marginBottom: '10px',
  height: '70px',
  backgroundColor: 'white',
  border: 'none',
  outline: 'none',
  borderRadius: '5px',
  boxShadow: '0px 3px 12px 0px rgba(0,0,0,0.4)',
  WebkitBoxShadow: '0px 3px 12px 0px rgba(0,0,0,0.4)',
  MozBoxShadow: '0px 3px 12px 0px rgba(0,0,0,0.4)',
  
  display:'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const countText = {
  fontSize: '33px',
  color:'grey',
  textAlign: 'center',
}

const dropdownTitleContainer = {
  height:'70px',
  width:'120px',
  display:'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection:'column'
}

const dropdownMenu = {
  width:'120px',
  height:'30px',
  fontSize:'20px',
  outline:'none',
  backgroundColor: 'white',
  border: 'none',
  outline: 'none',
  borderRadius: '5px',
  textAlign: 'center',
  color:'grey'
}

const dropdownMenuContainer = {
  width:'120px',
  height:'30px',
  marginTop:'5px',
  borderRadius: '5px',
  boxShadow: '0px 3px 12px 0px rgba(0,0,0,0.4)',
  WebkitBoxShadow: '0px 3px 12px 0px rgba(0,0,0,0.4)',
  MozBoxShadow: '0px 3px 12px 0px rgba(0,0,0,0.4)',
  
}

const titleFiber = {
  fontSize: '20px',
  fontWeight: 'bold',
  textAlign: 'center',
  width:'140px',
}