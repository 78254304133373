

/*
This object holds all the state variables globally (similar to object oriented programming - but for javascript!). 
It keeps track of the user and where they are in the app, which buttons have been clicked and so on. Throughout the lifetime of 
the app, there will only be 1 UserFrame object for simplicity, even though in redux it is in an array. Each time a change is dispatched 
to the redux store, it forces a re-render (using the useSelector) so that everything looks and runs smoothly.
*/
class State{

    height;
    width;
    foodItems;

   
    /*
    Initialize the variables
    */
    constructor(){
        this.height = 0;
        this.width = 0;
        this.foodItems = [];
    }
}

export {State};